export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCXSW8aBJtWqjZHD2faXbAabiXfAy3VPm4',
    authDomain: 'chefssustainablefish.firebaseapp.com',
    databaseURL: 'https://chefssustainablefish.firebaseio.com',
    projectId: 'chefssustainablefish',
    storageBucket: 'chefssustainablefish.appspot.com',
    messagingSenderId: '213308966262',
    appId: '1:213308966262:web:be0f6c82fefea3a2a56657',
    measurementId: 'G-EFXKFE1DXT',
  }
};

